.star-ratings {
    svg {
        width: 20px !important;
        height: 20px !important;
    }
}
.rating-section {
    span {
        margin-right: 10px;
        font-size: 13px;
        font-weight: 500;
    }
}