.image-preview {
  border-radius: 5px;
  text-align: center;
  position: relative;
  overflow: hidden;
}
.img-base {
  max-height: 400px;
  width: 100%;
  margin: auto;
}
.logo-preview {
  position: absolute;
}
.watermark-logo {
  width: auto;
  height: 100%;
  max-height: 45px;
  margin: auto;
  border: 2px solid #f5f8fa;
  border-radius: 5px;

}
.watermark-logo-input {
  max-height: 45px;
  width: 45px;
  padding: 5px !important;
  cursor: pointer;
  position: relative;
  float: right;
}
.watermark-logo-input > input {
  opacity: 0;
  width: 45px;
  height: 45px;
  position: absolute;
  cursor: pointer;
  left: 0;
  top: 0;
}
.watermark-logo-input > span {
  margin: auto !important;
  margin-right: 0px;
}
