.order-detail-item-card-title {
  img {
    height: 200px;
  }
}
.order-item-row {
  max-width: 650px;
  max-height: 250px;
  .svg-icon-success {
    max-width: 50px;
  }
  div {
    min-width: 280px;
    height: 100%;
    background-color: rgba(128, 128, 128, 0.331) !important;
    span {
      height: 100%;
      width: 100%;
      min-height: 200px;
      img {
        max-height: 240px;
        max-width: 280px;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
.text-wrap {
  max-width: 180px;
  a {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
