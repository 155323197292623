.main-item-image {
  height: auto;
  text-align: center;
  span {
    width: 100%;
  }
  img {
    max-height: 700px;
    height: auto;
    // width: auto !important;
    // max-width: 100%;
  }
  .lazy-load-image-background {
    img {
      width: 100%;
    }
  }
}
.compare-detail-item {
  // width: 100%;
  max-height: 700px;
  text-align: center;
}
