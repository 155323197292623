.order-item-card-title {
  position: relative;
  background-color: rgba(128, 128, 128, 0.331);
  text-align: center;
  img {
    width: 100%;
    height: 250px;
  }
}
.card-order-status {
  position: absolute;
  top: 10px;
  right: 10px;
}
.card-selection-box {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 999;
}
.card-order-service {
  position: absolute;
  bottom: 10px;
  left: 10px;
}
.order-card {
  background-color: #f5f8fa;
}
.order-card-disable {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - var(--bs-gutter-x));
  height: 100%;
  z-index: 10;
  background: rgb(128, 128, 128, 0.4);
  border-radius: 5px;
  margin-left: calc(var(--bs-gutter-x) * 0.5);
}
.order-card {
  position: relative;
  background: transparent;
}

.order-card-title {
  line-height: 37px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
