.service-img-compare {
  overflow: hidden;
  width: 100%;
  img {
   width: 100%;
   min-height: 180px;
  }
}
.service-badge {
  position: absolute;
  width: auto;
  height: 20px;
  top: -10px;
  right: -10px;
}
