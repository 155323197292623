.card-img-top {
  transition: opacity 3s ease;
}
.note {
  font-size: 11px;
}
.item-upload-image {
  max-height: 45px;
  margin: auto;
  width: auto;
  max-width: 100%;
  border-radius: 5px;
}
.item-upload-input {
  opacity: 0;
  max-width: 80px;
  position: absolute;
}

.logo-box {
  border: 1px solid var(--bs-card-border-color);
  display: flex;
  align-items: flex-end;
  width: 80%;
  background-color: #f5f8fa;
  border-radius: 0.475rem;
}
