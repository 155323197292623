.list-group {
    display: -webkit-inline-box !important;
}
.list-group-item  {
    text-align: left;
    border: none !important;
}

.feature-img-compare {
    border-radius: 20px;
    box-shadow: 10px 10px 6px #00000029;
    overflow: hidden;
    padding: 0;
}